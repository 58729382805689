<template>
  <div>
    <p v-show="errorTableData" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      Error obteniendo los datos
    </p>

    <v-card v-if="!errorTableData">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :search="search"
        :loadingTableData="loadingTableData"
        loadingTableData-text="Cargando..."
        @click:row="rowClick"
      >
        <template v-slot:item.fecha="{ item }">
          {{ new Date(item.fecha).toLocaleDateString() }}
        </template>

        <template v-slot:item.pagado="{ item }">
          <v-chip v-if="item.pagado" color="success" small>
            Pagado
          </v-chip>

          <v-chip v-else color="error" small>
            Pendiente
          </v-chip>
        </template>

        <template v-slot:item.total="{ item }"> {{ item.total }} € </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: false,
      loadingTableData: true,
      search: '',
      tableHeaders: [
        { text: 'Número', value: 'numero' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Clientes', value: 'clientes' },
        { text: 'Forma de pago', value: 'formaPago' },
        { text: 'Importe', value: 'total' },
        { text: 'Estado', value: 'pagado' },
      ],
    };
  },
  methods: {
    async getTableData() {
      try {
        this.loadingTableData = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/recibos');

        this.tableData = res.data;

        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;

        this.errorTableData = true;
      }
    },
    async rowClick(row) {
      this.$router.push({ name: 'Recibo', params: { id: row._id } });
    },
  },
  mounted() {
    this.getTableData();
    this.$emit('usePanel', true);
  },
};
</script>
